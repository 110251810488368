


















































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Watch } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import AddCarportModal from './addCarport-modal.vue'
import TipModal from '@/views/common/tip-modal.vue'
import { getDicts, getDictName } from '@/utils/dict'
import { getExportExcel } from '@/utils/export'
import { Select, Option } from 'element-ui'
Vue.use(Select)
Vue.use(Option)

@Component({
  components: {
    Table,
    Pagination,
    AddCarportModal,
    TipModal
  }
})
export default class CarportList extends Vue {
  $constants: any
  tabActive = ''
  // 字典数据
  // 小区期数
  areaDicts: any = []
  // 车位类型
  carportTypeDicts: any = []
  // 车位状态
  carportStatusDicts: any = []
  // 车辆类型
  carTypeDicts: any = []
  listLoading = false
  tableHeader = [
    {
      key: 'carPlaceNo',
      name: '车位编号',
      width: '10%'
    },
    {
      key: 'area',
      name: '区域',
      width: '10%'
    },
    {
      key: 'carPlaceType',
      name: '车位类型',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'houseNo',
      name: '所属房屋',
      width: '10%'
    },
    {
      key: 'status',
      name: '车位状态',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'carNo',
      name: '当前绑定车辆',
      width: '10%'
    },
    {
      key: 'carType',
      name: '车辆类型',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'dueDate',
      name: '到期时间',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'remark',
      name: '备注',
      width: '10%',
      remark: true
    },
    {
      key: 'opt',
      name: '操作',
      width: '10%',
      isSpecial: true
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  carportType = ''
  carportState = ''
  searchModel = {
    key: 'houseNo',
    value: null
  }
  isOpenModal = false
  isOpenTipModal = false
  deleteCarportId = ''

  isOpenBathTipModal = false

  // 批量操作 按钮 显隐控制
  batchOpt = false
  currentHoverBtn = ''
  //是否清除表格勾选
  isClearChecked = false
  // 是否有表格勾选
  tableChoose = false
  // 勾选的list
  checkedList = [] as any

  @Watch('tabActive')
  gettabActive() {
    this.getCarportList()
  }

  async beforeCreate() {
    // 获取相关字典
    // 小区期数
    this.areaDicts = await getDicts('PHASE')
    // 车位类型
    this.carportTypeDicts = await getDicts('CAR_PLACE_TYPE')
    // 车位状态
    this.carportStatusDicts = await getDicts('CAR_PLACE_STATUS')
    // 车辆类型
    this.carTypeDicts = await getDicts('CAR_TYPE')
  }

  mounted() {
    if (this.$route.query.houseNo) {
      this.searchModel.key = 'houseNo'
      ;(this.searchModel.value as any) = this.$route.query.houseNo
    }
    this.getCarportList()
  }

  searchFn() {
    this.listQuery.page = 1
    this.getCarportList()
  }

  // 获取车位列表
  getCarportList() {
    this.listLoading = true
    this.$api.car
      .getCarportList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        area: this.tabActive,
        carPlaceType: this.carportType,
        status: this.carportState,
        [this.searchModel.key]: this.searchModel.value
      })
      .then((res: any) => {
        this.listLoading = false
        this.tableData = []
        const data = res.data
        // this.tableData = data.data
        this.tableData = data.data.map((v: any) => {
          v['isChecked'] = false
          return v
        })
        this.checkedList = []
        this.isClearChecked = true
        this.total = data.total
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }

  tabAll() {
    this.tabActive = ''
  }
  tabChange(val: string) {
    this.tabActive = val
  }

  // btn移入移出控制样式
  hoverbtn(kind: string) {
    this.currentHoverBtn = kind
  }
  defaultbtn() {
    this.currentHoverBtn = ''
  }
  //取消操作
  cancelOpt() {
    this.batchOpt = false
    this.tableChoose = false
    this.isClearChecked = false
    this.tableData.forEach((v: any) => {
      v['isChecked'] = false
      return v
    })
  }

  // 获取当前已选
  getCheckedList(list: any) {
    this.checkedList = list
  }

  // 批量导出
  doExport() {
    if (!this.checkedList.length) {
      this.$message.error('请选择车位')
      return
    }
    const ids: any = []
    this.checkedList.forEach((x: any) => {
      ids.push(x.id)
    })
    const p = {
      exportType: 'carPlace',
      area: this.tabActive,
      carPlaceType: this.carportType,
      status: this.carportState,
      ids: ids,
      [this.searchModel.key]: this.searchModel.value
    }
    getExportExcel(p, '车位导出.xlsx')
  }

  batchDeleteCarport() {
    if (!this.checkedList.length) {
      this.$message.error('请选择车位')
      return
    }
    const ids: any = []
    this.checkedList.forEach((x: any) => {
      ids.push(x.id)
    })
    this.$api.car.batchDeleteCarport(ids).then((res: any) => {
      if (res.data.success) {
        this.isOpenBathTipModal = false
        this.$message.success('车位删除成功')
        this.getCarportList()
        this.cancelOpt()
      }
    })
  }

  // 关闭对话框
  closeDialog() {
    this.isOpenBathTipModal = false
    this.isOpenModal = false
    this.isOpenTipModal = false
    this.deleteCarportId = ''
  }
  // 操作对话框后统一回调
  afterOpt() {
    this.closeDialog()
    this.getCarportList()
  }

  // 删除车位
  deleteCarport() {
    this.$api.car.deleteCarport(this.deleteCarportId).then((res: any) => {
      if (res.data.success) {
        this.isOpenTipModal = false
        this.$message.success('车位删除成功')
        this.getCarportList()
        this.deleteCarportId = ''
      }
    })
  }
  // 跳转到修改车位信息
  toEdit(id: number) {
    this.$router.push({
      path: '/cars/carport-info',
      query: {
        id: id + ''
      }
    })
  }
}
